<template>
    <div>
        <v-dialog
            v-model="sampleTestsDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <sample-tests-form
                :key="sampleTestsKey"
                :sample="editedSample"
                @close="sampleTestsDialog = false" />
        </v-dialog>
        <v-row>
            <v-col>
                <v-switch v-model="showCompletedSamples" label="Show Completed Samples" />
            </v-col>
            <v-col>
                <v-btn-toggle
                    v-model="sampleToggle"
                    mandatory
                    class="ml-2 float-right"
                    tile
                    group>
                    <v-btn>
                        Active
                    </v-btn>
                    <v-btn>
                        Testing
                    </v-btn>
                    <v-btn>
                        Completed
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <div class="d-flex pb-1 pl-1 pr-2 pt-2 lab-test-dashboard-sample-set">
            <v-card
                v-for="(sampleSet, i) in sampleSets"
                :key="i+'sampleSet'"
                class="d-flex flex-column mr-2 flex-grow-1"
                max-width="300px">
                <v-card-title>
                    {{ sampleSet.name }}
                </v-card-title>
                <div class="scrollbar-container">
                    <perfect-scrollbar
                        class="scroll-height"
                        :options="{ suppressScrollX: true }">
                        <sample
                            v-for="(sample, index) in sampleSet.samples"
                            :key="index"
                            class="ma-2"
                            :sample="sample"
                            v-on="$listeners"
                            @selected="onSelected" />
                    </perfect-scrollbar>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios"
import { list } from "@/services/schemaApi"
import { lessThan, equal, notEqual, and, greaterThan, or } from "@/services/filtering"
import { isEmpty } from "@/services/objectUtility"
import dateUtility from "@/services/dateUtility"
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);
export default {
    components: {
        sampleTestsForm: () => import("@/views/labTestDashboard/SampleTestsForm"),
        sample: () => import("@/views/labTestDashboard/Sample")
    },
    props: {
        filter: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sampleSets: [],
            editedSample: null,
            sampleTestsDialog: false,
            sampleTestsKey: 0,
            showCompletedSamples: false,
            sampleToggle: 1
        };
    },
    computed: {
        toggleFilter() {
            if (this.sampleToggle === 0) {
                return greaterThan(
                    "SamplesToBeMailedOn",
                    dateUtility.getCurrentDateIso(),
                    "datetimeoffset");
            }
            if (this.sampleToggle === 1) {
                let now = dateUtility.getCurrentDateIso();
                return and([
                    lessThan("SamplesToBeMailedOn", now, "datetimeoffset"),
                    equal("CompletedOn", null, "datetimeoffset?")]);
            }
            if (this.sampleToggle === 2) {
                return notEqual("CompletedOn", null, "datetimeoffset?");
            }
            return null;
        }
    },
    watch: {
        showCompletedSamples: {
            async handler() {
                await this.load();
            }
        },
        toggleFilter: {
            async handler() {
                await this.load();
            }
        }
    },
    async mounted() {
        this.isTesting = true;
        await this.load();
    },
    methods: {
        async load() {
            try {
                let sampleSets = await this.getSampleSets();
                let sampleSetSamples = await this.getSampleSetSamples(sampleSets);
                let samples = await this.getSamples(sampleSetSamples);
                this.setSampleSets(sampleSets, sampleSetSamples, samples);
            } catch (error) {
                this.handleError(error);
            }
        },
        async getSampleSets() {
            let params = { sortBy: "samplesToBeMailedOn" };
            if (!isEmpty(this.toggleFilter)) {
                params.filter = this.toggleFilter
            }
            let response = await axios.post("/api/SampleSets/List", params);
            let sampleSets = response.data.items;
            let organisations = await this.getLabClientOrganisations(sampleSets);
            sampleSets.forEach(e => e.name = this.formatIsoAsDate(e.samplesToBeMailedOn) + " "
                + organisations.filter(i => i.id === e.labClientOrganisationId)[0].name);
            return sampleSets;
        },
        async getLabClientOrganisations(sampleSets) {
            let organisationIds = sampleSets.map(e => e.labClientOrganisationId);
            let response = await axios.post(
                "/api/LabClientOrganisations/ListFromIds", organisationIds);
            return response.data.items;
        },
        async getSampleSetSamples(sampleSets) {
            let sampleSetIds = sampleSets.map(e => e.id);
            let params = {};
            params.filter = or(sampleSetIds.map(e => equal("SampleSetId", e, "Guid")));
            let results = await list("SampleSetSamples", params);
            return results.items;
        },
        async getSamples(sampleSetSamples) {
            let sampleIds = sampleSetSamples.map(e => e.sampleId);
            let params = {};
            params.filter = and([
                or(sampleIds.map(e => equal("Id", e, "Guid"))),
                equal("IsTestingCompleted", this.showCompletedSamples, "bool")
            ]);
            let response = await axios.post("/api/Samples/List", params);
            return response.data.items;
        },
        setSampleSets(sampleSets, sampleSetSamples, samples) {
            for (let i = 0; i < sampleSets.length; i++) {
                this.mutateSampleSet(sampleSets[i], sampleSetSamples, samples);
            }
            this.sampleSets = sampleSets;
        },
        mutateSampleSet(sampleSet, sampleSetSamples, samples) {
            let samplesForSampleSet = samples
                .filter(e => sampleSetSamples
                    .some(s => s.sampleSetId === sampleSet.id && s.sampleId === e.id))
                .sort((a, b) => a.code.localeCompare(b.code));
            sampleSet.samples = samplesForSampleSet;
        },
        onSelected(sample) {
            this.editedSample = sample;
            this.sampleTestsKey++;
            this.sampleTestsDialog = true;
        }
    }
}
</script>
<style scoped>
    .lab-test-dashboard-sample-set {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 40px;
    }

    .scroll-height {
        height: 60vh;
    }

    .scrollbar-container {
        overflow: auto;
        position: relative;
    }
</style>
