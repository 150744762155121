import schemaService from "@/services/schemaService"
import axios from "axios"

//This service exists to ensure that AppSearchSelectMultiple can be copy pasted
//in future and is not overfitted the old code in this app
export async function get(entityKey, id) {
    let schema = schemaService.getSchema(entityKey);
    let response = await axios.get("/api/" + schema.pascalPlural + "/" + id);
    return response.data;
}

export async function list(entityKey, model) {
    let schema = schemaService.getSchema(entityKey);
    let response = await axios.post("/api/" + schema.pascalPlural + "/List", model);
    return response.data;
}

export default {
    get,
    list
};
